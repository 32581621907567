'use client';
import { GenrePaths } from '~/common/types/genre';
import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';

import { Box, Grid, HStack, Image, VStack } from '@styled-system/jsx';
import newIcon from './assets/new-icon.svg';
import rankingIcon from './assets/ranking-icon.svg';
import vipBannerSp from './assets/vip-banner-sp.png';
import vipBanner from './assets/vip-banner.png';

type Props = {
  onClickMyStories?: () => void;
  onClickLogout?: () => void;
  isAnonymous?: boolean;
  isVip?: boolean;
};

export const HeaderMenuContent = ({
  onClickMyStories,
  onClickLogout,
  isAnonymous,
  isVip,
}: Props) => {
  return (
    <VStack
      gap="0"
      maxWidth={{ lg: '380px' }}
      maxHeight={{ lg: '75vh' }}
      overflowY="scroll"
    >
      {/* Vip Banner */}
      {!isVip && (
        <>
          <Link
            href="/vip_offer"
            className={css({
              _focus: {
                outline: 0,
                outlineColor: 'transparent',
                outlineStyle: 'none',
              },
              lg: {
                padding: '15px',
              },
            })}
          >
            <Image
              display={{ lg: 'none' }}
              src={vipBannerSp}
              alt="VIPプラン"
              loading="lazy"
              w="100%"
            />
            <Image
              display={{ lgDown: 'none' }}
              src={vipBanner}
              alt="VIPプラン"
              loading="lazy"
              w="100%"
            />
          </Link>
        </>
      )}

      {/* Ranking / new stories */}
      <HStack
        width="100%"
        height="56px"
        minHeight="56px"
        maxWidth="400px"
        justifyContent="space-around"
        gap="0"
      >
        <Link
          href={routes.genre.ranking.url('horror')}
          className={css({
            _hover: { opacity: '0.8' },
            _focus: {
              outline: 0,
              outlineColor: 'transparent',
              outlineStyle: 'none',
            },
          })}
        >
          <HStack>
            <Image src={rankingIcon} alt="ランキング" w="32px" h="32px" />
            ランキング
          </HStack>
        </Link>
        <Link
          href={routes.stories.url()}
          className={css({ _hover: { opacity: '0.8' } })}
        >
          <HStack>
            <Image src={newIcon} alt="新着" w="32px" h="32px" />
            新着
          </HStack>
        </Link>
      </HStack>

      {/* Separator */}
      <Box
        w="100%"
        backgroundColor="strong_bg"
        height="18px"
        minHeight="18px"
      />

      {/* Genres */}
      <Grid
        w="100%"
        columns={{ base: 2 }}
        fontSize="size_13"
        fontWeight="700"
        textAlign="center"
        backgroundColor="strong_bg"
        gap="2px"
        marginTop="-10px"
        padding="0"
      >
        {GenrePaths.map((genre) => (
          <Link
            href={routes.genre.ranking.url(genre.path)}
            key={genre.path}
            className={css({
              height: '46px',
              margin: 0,
              padding: '15px 12px',
              backgroundColor: 'white',
              _hover: {
                opacity: '0.8',
              },
            })}
          >
            {genre.genre}
          </Link>
        ))}
        <Box
          className={css({
            margin: 0,
            padding: '15px',
            backgroundColor: 'white',
          })}
        />
      </Grid>
      {/* Separator */}
      <Box
        w="100%"
        backgroundColor="strong_bg"
        height="8px"
        minHeight="8px"
        lg={{
          display: isAnonymous ? 'none' : 'block',
        }}
      />

      {!isAnonymous && (
        <>
          {/* User Menu */}
          <VStack
            padding="26px 0 0 24px"
            fontSize="size_13"
            justifyContent="left"
            alignItems="flex-start"
            width="100%"
            textAlign="left"
          >
            <Link
              href={routes.myPage.url()}
              className={css({
                height: '36px',
                _hover: {
                  opacity: '0.8',
                },
              })}
            >
              マイページ／設定
            </Link>
            <Box
              onClick={onClickMyStories}
              className={css({
                height: '36px',
                _hover: {
                  opacity: '0.8',
                  cursor: 'pointer',
                },
              })}
            >
              自分のストーリー
            </Box>
            <Box
              onClick={onClickLogout}
              className={css({
                height: '36px',
                _hover: {
                  opacity: '0.8',
                  cursor: 'pointer',
                },
              })}
            >
              ログアウト
            </Box>
          </VStack>
        </>
      )}
    </VStack>
  );
};
