'use client';
import { Popover, Portal } from '@ark-ui/react';
import { useCallback } from 'react';
import type { PropsWithChildren } from 'react';

import { HamburgerCloseIcon } from '@app/_components/header/hamburger-close-icon';
import { styled } from '@styled-system/jsx';
import { HeaderMenuContent } from './header-menu-content';

type Props = PropsWithChildren<{
  onOpen?: () => void;
  isOpen: boolean;
  onClickMyStories?: () => void;
  onClose?: () => void;
  onClickLogout?: () => void;
  isAnonymous?: boolean;
  isVip?: boolean;
}>;

export const PopoverMenu = ({
  onOpen,
  isOpen,
  onClose,
  onClickMyStories,
  onClickLogout,
  isAnonymous,
  isVip,
}: Props) => {
  const handleOnOpenChange = useCallback(
    ({ open }: { open: boolean }) => {
      if (!open) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <Popover.Root
      portalled
      open={isOpen}
      onOpenChange={handleOnOpenChange}
      modal
    >
      <Popover.Trigger>
        <HamburgerCloseIcon isShowClose={isOpen || false} onClick={onOpen} />
      </Popover.Trigger>
      <Portal>
        <PopoverPositioner>
          <PopoverContent>
            <HeaderMenuContent
              onClickMyStories={onClickMyStories}
              onClickLogout={onClickLogout}
              isAnonymous={isAnonymous}
              isVip={isVip}
            />
          </PopoverContent>
        </PopoverPositioner>
      </Portal>
    </Popover.Root>
  );
};

const PopoverPositioner = styled(Popover.Positioner, {
  base: {
    alignItems: 'center',
    zIndex: 'drawer',
    marginTop: '20px',
  },
});

const PopoverContent = styled(Popover.Content, {
  base: {
    background: 'white',
    boxShadow: '0px 4px 12px 0px #00000040',
    textAlign: 'center',
    borderRadius: '8px',
    zIndex: 'drawer',
    _open: {
      animation: 'fadein 150ms ease-out',
    },
    _closed: {
      animation: 'fadeout 300ms ease-out',
    },
  },
});
